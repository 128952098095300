var Aura = {
  $: jQuery.noConflict( true ),
  navSwap: null
};
Aura.$(window).scroll(function() {
    scrolled();
});
var navSwap = null;
Aura.$(document).ready(function() {
  resize();
  scrolled();
  var slick = {
    "slidesToShow": 4,
    "slidesToScroll": 4
  };
  if( Aura.$(window).width() < 1000 ) {
    slick={
      "slidesToShow": 3,
      "slidesToScroll": 3
    };
  }
  if( Aura.$(window).width() < 768 ) {
    slick={
      "slidesToShow": 1,
      "slidesToScroll": 1
    };
  }
  Aura.$('.our-team-slick').slick(slick);
  Aura.$(document).on("click",".search-icon",function(){
	  Aura.$("#search").toggleClass("enabled");
	  if( Aura.$("#search").hasClass("enabled") ) {
		  Aura.$(".search-text").focus();
	  }
  })
});
Aura.$(window).resize(function() {
	resize();
    scrolled();
});
function resize() {
    if( Aura.$(window).width() < 768 ) {
      Aura.$('.navbar').addClass('navbar-scrolled');
    } else {
      	Aura.$('.navbar').removeClass('navbar-scrolled');
    	if (Aura.$('.navbar[data-navbar-swap]')) {
        var ns = Aura.$('.navbar[data-navbar-swap]');
        Aura.navSwap = {
            position: ns.data('navbar-swap'),
            class: ns.data('navbar-swap-class')
        };
    	}
  	}
}
function scrolled() {
	if( Aura.$(window).width() > 768 ) {
      if (Aura.navSwap) {
          if (Aura.$(window).scrollTop() > Aura.navSwap.position) {
              Aura.$('.navbar').removeClass(Aura.navSwap.class);
              Aura.$('.navbar').addClass('navbar-scrolled');
          } else {
              Aura.$('.navbar').addClass(Aura.navSwap.class);
              Aura.$('.navbar').removeClass('navbar-scrolled');
          }
      }
    }
}
